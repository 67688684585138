
import './App.css';
import { lazy, Suspense, useEffect, useState,} from 'react';

import Header from './Header';
import { Route, Routes } from 'react-router-dom';
import Footer from './Footer';
import { useRecoilSnapshot, useRecoilState, useRecoilValue } from 'recoil';
import language, { mylogin, myname } from './Main_menus/Atom_File';
import Privacy_en from './Privacy_en';
import Privacy_hi from './Privacy_hi';
import Loading_Page from './Loading_Page';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap';
import Loading_Form from './Loading_Form';
import Loading_Login from './Loading_Login';
import Make_Payment from './Main_menus/Make_Payment';
import NRC from './internship/NRC';
const RefundPolicy = lazy (() => import('./RefundPolicy'))
const Registration = lazy(() => import('./Registration'));
const Registration_hi = lazy(() => import('./Registration_hi'));
const Login = lazy(() => import('./Main_menus/Login'));
const Forgot_Password = lazy(() => import('./Main_menus/Forgot_Passowrd'));
const Home = lazy(() => import('./Main_menus/Home'));
const Home_hindi = lazy(() => import('./Main_menus/Home_hi'));
const About = lazy(() => import('.//Main_menus/About'));
const About_hindi = lazy(() => import('.//Main_menus/About_hi'));
const Vision = lazy(() => import('./Main_menus/Vision'));
const Vision_hindi = lazy(() => import('./Main_menus/Vision_hi'));
const Contact = lazy(() => import('.//Main_menus/Contact'));
const Contact_Hindi = lazy(() => import('.//Main_menus/Contact_hi'));
const Courses = lazy(() => import('./Courses'));
const Terms_Conditions = lazy (() => import ('./Terms_Conditions'));
const ClinicalNeurology = lazy(() => import('./internship/ClinicalNeurology'));
const StrokeNeurology = lazy(() => import('./internship/StrokeNeurology'));
const Physiotherapy = lazy(() => import('./internship/Physiotherapy'));
const SpeechTherapy = lazy(() => import('./internship/SpeechTherapy'));
const NeuroElectrophysiology = lazy(() => import('./internship/NeuroElectro'));
const PainManagement = lazy(() => import('./internship/Pain'));
const BreastfeedingWomenHealth = lazy(() => import('./internship/Breastfeeding'));
const ClinicalNeurology_hi = lazy(() => import('./internship/ClinicalNeurology_hi'));
const StrokeNeurology_hi = lazy(() => import('./internship/StrokeNeurology_hi'));
const Physiotherapy_hi = lazy(() => import('./internship/Physiotherapy_hi'));
const SpeechTherapy_hi = lazy(() => import('./internship/SpeechTherapy_hi'));
const NeuroElectrophysiology_hi = lazy(() => import('./internship/NeuroElectro_hi'));
const PainManagement_hi = lazy(() => import('./internship/Pain_hi'));
const BreastfeedingWomenHealth_hi = lazy(() => import('./internship/Breastfeeding_hi'));
const NAAMH = lazy(() => import('./NAAMH'));
const NAAMH_hi = lazy(() => import('./NAAMH_hi'));
const IAAMP = lazy(() => import('./IAAMP'));
const IAAMP_hi = lazy(() => import('./IAAMP_hi'));

function App() {
  const mylang = useRecoilValue(language);
  const [islogin, setLogin] = useRecoilState(mylogin);
  const [isname, setmyname] = useRecoilState(myname);
  const [isshowmodal, setshowmodal] = useState(false);
  const handleCloseModal = () => {
    setshowmodal(false);
  };

const location = useLocation();
const searchParams = new URLSearchParams(location.search);
const value = searchParams.get('value');

useEffect(() => {
  
  login_user();
  
}, [])
 
useEffect(() => {

      if(value==='success')
      {
        setshowmodal(true);
      }
      
    }, [value])
    
    const login_user = async () => {
      const url = 'https://arth-dhwani.com/pame/server_files/login.php';
      try {
        const response = await axios.post(url, { withCredentials: true });
            
             if(response.data!==5)
             {
                setLogin(true);
                setmyname(response.data);
             }

      }   
       catch (error) {
        
        alert('There is some Error in your Data, Please Try after some time');
      } finally {

      }
    };    
    

  
    
    

  return (
    <>           
      <Header />
      <Routes>
        <Route
          path="/"
          element={mylang==='English'?
            <Suspense fallback={<div><Loading_Page/></div>}>
              <Home /><Modal show={isshowmodal} onHide={handleCloseModal}>
    <Modal.Header closeButton>
      <Modal.Title><span style={{color:'green'}}>Success</span></Modal.Title>
    </Modal.Header>
    <Modal.Body>
      You are successfully registered!!
      <p>We Will Contact You Soon !!</p>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="secondary" onClick={handleCloseModal}>
        Close
      </Button>
    </Modal.Footer>
  </Modal>
            </Suspense>:   <Suspense fallback={<div><Loading_Page/></div>}>
              <Home_hindi />
            </Suspense>
          }
        />

        <Route
          path="/about"
          element={mylang=='English'?
            <Suspense fallback={<div><Loading_Page/></div>}>
             
              <About />
            </Suspense>:<Suspense fallback={<div><Loading_Page/></div>}>
              <About_hindi/>
            </Suspense>
          }
        />
        <Route
          path="/vision"
          element={mylang=='English'?
            <Suspense fallback={<div><Loading_Page/></div>}>
              <Vision />
            </Suspense>: <Suspense fallback={<div><Loading_Page/></div>}>
              <Vision_hindi/>
            </Suspense>
          }
        />
        <Route
          path="/courses"
          element={
            <Suspense fallback={<div><Loading_Page/></div>}>
              <Courses />
            </Suspense>
          }
        />
        <Route
          path="/contact"
          element={mylang==='English'?
            <Suspense fallback={<div><Loading_Page/></div>}>
              <Contact />
            </Suspense>:
            <Suspense fallback={<div><Loading_Page/></div>}>
            <Contact_Hindi />
          </Suspense>
          }
        />
        <Route
          path="/privacy-policy"
          element={mylang==='English'? <Suspense fallback={<div><Loading_Page/></div>}> <Privacy_en/></Suspense> : <Suspense fallback={<div><Loading_Page/></div>}> <Privacy_hi/>      
            </Suspense>
          }
        />

<Route path="/clinical-neurology" element={mylang === 'English' ? (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <ClinicalNeurology />
  </Suspense>
) : (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <ClinicalNeurology_hi />
  </Suspense>
)} />

<Route path="/stroke-neurology" element={mylang === 'English' ? (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <StrokeNeurology />
  </Suspense>
) : (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <StrokeNeurology_hi />
  </Suspense>
)} />

<Route path="/physiotherapy" element={mylang === 'English' ? (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <Physiotherapy />
  </Suspense>
) : (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <Physiotherapy_hi />
  </Suspense>
)} />

<Route path="/speech-therapy" element={mylang === 'English' ? (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <SpeechTherapy />
  </Suspense>
) : (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <SpeechTherapy_hi />
  </Suspense>
)} />

<Route path="/neuro-electrophysiology" element={mylang === 'English' ? (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <NeuroElectrophysiology />
  </Suspense>
) : (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <NeuroElectrophysiology_hi />
  </Suspense>
)} />

<Route path="/pain-management" element={mylang === 'English' ? (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <PainManagement />
  </Suspense>
) : (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <PainManagement_hi />
  </Suspense>
)} />

<Route path="/breastfeeding-women-health" element={mylang === 'English' ? (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <BreastfeedingWomenHealth />
  </Suspense>
) : (
  <Suspense fallback={<div><Loading_Page/></div>}>
    <BreastfeedingWomenHealth_hi />
  </Suspense>
)} />

<Route
          path="/naamh"
          element={mylang==='English'?
            <Suspense fallback={<div><Loading_Page/></div>}>
              <NAAMH/>
            </Suspense>:
            <Suspense fallback={<div><Loading_Page/></div>}>
            <NAAMH_hi/>
          </Suspense>
          }
        />
<Route
          path="/iaamp"
          element={mylang==='English'?
            <Suspense fallback={<div><Loading_Page/></div>}>
              <IAAMP/>
            </Suspense>:
            <Suspense fallback={<div><Loading_Page/></div>}>
            <IAAMP_hi/>
          </Suspense>
          }
    />
    <Route
          path="/neurology-refresher-course"
          element={
            <Suspense fallback={<div><Loading_Page/></div>}>
              <NRC/>
            </Suspense>
          }
    />
<Route
          path="/registration"
          element={mylang==='English'?
            <Suspense fallback={<div><Loading_Form/></div>}>
            <Registration/>            </Suspense>:
            <Suspense fallback={<div><Loading_Form/></div>}>
            <Registration_hi/>
          </Suspense>
          }
    />   
    <Route path='/login' element={<Suspense fallback={<div><Loading_Login/></div>}><Login></Login></Suspense>} />
    <Route path='/forgotp' element={<Suspense fallback={<div><Loading_Login/></div>}><Forgot_Password/></Suspense>} />
    <Route path='/refund-policy' element={<Suspense fallback={<div><Loading_Page/></div>}><RefundPolicy></RefundPolicy></Suspense>} />
    <Route path='/terms-and-condition' element={<Suspense fallback={<div><Loading_Page/></div>}><Terms_Conditions></Terms_Conditions></Suspense>} />
    <Route path='/make-payment' element={<Suspense fallback={<div><Loading_Form/></div>}><Make_Payment></Make_Payment></Suspense>} />
    

      </Routes>
      <Footer></Footer>
    </>
  );
}

export default App;
