import { Col, Container, Row, Dropdown} from "react-bootstrap";
import { useRecoilValue } from "recoil";
import language from "./Main_menus/Atom_File";
import { lazy,Suspense } from "react";
import LoadingHeader from "./LoadingHeader";
import LoadingMenus from "./LoadingMenus";
import { Link } from "react-router-dom";
import { mylogin, myname } from "./Main_menus/Atom_File";
import Language_component from "./Main_menus/Language_component";
import new_img from '../src/headers_imgs/new.gif';
//import Menu_Hi from "./Menu_Hi";
const Header_text_hi = lazy(() => import('./Header_Text_hi'));
const HeaderText = lazy(()  => import('./Hader_Text'));

function Header() {
  const Menu_Hi = lazy(() => import('./Menu_Hi'));
  const Menu_En = lazy(() => import('./Menu_En'));
  const lang = useRecoilValue(language);
  const islogin = useRecoilValue(mylogin);
  const isname = useRecoilValue(myname);
  return (
    <>
      <Container fluid className="header">
        <Row className="text-center"><Col style={{display:'flex'}}><Language_component className='text-center'></Language_component></Col></Row>
        <Row>
        <Col className="text-center">
        
            {lang==='English'?<Suspense fallback={<LoadingHeader/>}><HeaderText/></Suspense>: <Suspense fallback={<LoadingHeader></LoadingHeader>}>
              <Header_text_hi/>
            </Suspense>}
            </Col>
                
       </Row>
        <Row style={{marginBottom:'10px'}}>
          <Col className="text-end">
        {islogin && isname!==''?<div >
          
        <Dropdown>
      <Dropdown.Toggle style={{backgroundColor:'rgb(0, 118, 148)', border:'1px solid black',borderRadius:'40px'}} id="dropdown-toggle">
    {lang==='English'?'Hello':'नमस्ते'}  , {isname}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item href="#action1">My Profile</Dropdown.Item>
        <Dropdown.Item href="#action2">Option 2</Dropdown.Item>
        <hr/>
        <Dropdown.Item href="#action3">Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>    
          
          
         </div>:<div ><Link className="link_class" to={'/registration'}>Student/ Interns Registration</Link> <Link className="link_class" to={'/login'}>Login</Link></div>}
        </Col></Row>
      </Container>
      {lang==='English'?<Suspense fallback={<LoadingMenus/>}><Menu_En/></Suspense>:<Suspense fallback={<LoadingMenus/>}><Menu_Hi/></Suspense>}

    </>
  );
}

export default Header;
